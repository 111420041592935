html,
body,
#root {
  height: 100%;
}

body.chakra-ui-light {
  background-color: #f1eefc;
}

button.selected {
  background: linear-gradient(90deg, #05c1c1 0%, #00d3d3 100%);
}
